<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="updateDevSoftware">
            <el-form :model="form" ref="form" :rules="rules" label-width="145px" label-position="left">
                <el-form-item label="原软件版本号" prop="originVersion">
                    <el-input v-model="form.originVersion" style="width: 450px"></el-input>
                </el-form-item>
                <el-form-item label="目标软件版本号" prop="updateVersion">
                    <el-input v-model="form.updateVersion" style="width: 450px"></el-input>
                </el-form-item>
                <el-form-item label="软件包" prop="package">
                    <el-upload
                        :action="$Config.axiosBaseUrl900C+$Config.apiUrl.updateDevSoftware"
                        ref="uploadRef"
                        class="upload-demo"
                        :headers="{
                            'token': 'mSjrjW8au6zRyKj5',
                        }"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :before-upload="beforeUpload"
                        :on-success="onSuccess"
                        :on-change="onChange"
                        accept='.bin'
                        :limit="1"
                        :http-request="uplaodFileFun"
                        :auto-upload="false"
                        :file-list="fileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.bin文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import Utils from '@/utils';
export default {

    data() {        
        return {
            Utils: Utils,
            form: {
                originVersion: '',
                updateVersion: '',
                package: '',//软件包
                filePath: '',//包存放路径
                packageSize: '',//包大小
            },
            rules:{
                originVersion: [{required: true, message:'请输入原软件版本号', trigger: 'blur'}],
                updateVersion: [{required: true, message:'请输入目标软件版本号', trigger: 'blur'}],
            },
            fileName: '',
            fileList:[],//文件上传列表
        };
    },
    created () {

    },

    mounted () {
        
    },
    methods: {
        onSuccess (res,file,fileList) {
            console.log(res);
        },
        handleRemove (file, fileList) {

        },
        beforeRemove(file, fileList) {
            if(file.name.split('.').pop().toLowerCase() === 'bin'){
                return this.$confirm(`确定移除 ${ file.name }？`);
            }
        },
        beforeUpload (file,fileList) {
            
            if (file.name.split('.').pop().toLowerCase() != 'bin') {
                this.$message.error('上传文件只能是 .bin 格式!');
                return false
            }
        },
        onChange (file,fileList) {
            this.fileName = file.name
            this.form.packageSize = file.size
            console.log(this.fileName,'文件名');
            console.log(this.form.packageSize,'文件大小');
            this.form.package = file.name
        },
        uplaodFileFun (param) {
            console.log(param);
            let reqData = {
                originVersion: this.form.originVersion,
                updateVersion: this.form.updateVersion,
                packageSize: this.form.packageSize,
                fileName: this.fileName
            }

            let formData = new FormData()
            formData.append('file', param.file)
            formData.append('originVersion', this.form.originVersion)
            formData.append('updateVersion', this.form.updateVersion)
            formData.append('packageSize', this.form.packageSize)
            formData.append('fileName', this.fileName)

            this.$Axios._post900C({
                url: this.$Config.apiUrl.updateDevSoftware,
                method: "post",
                params: formData
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.$message.success('操作成功')
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })

        },
        submit (params) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        this.$refs.uploadRef.submit()
                    })
                    .catch(_=>{})
                }
            })
        },
        cancelSubmit () {
            this.$router.back()
            this.$store.dispatch("delView", this.$route);
        }
    },
}
</script>

<style lang="scss" scoped>
    .updateDevSoftware{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
    }
</style>